import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
 
class WhoWeAre extends React.Component {
    render(){
        return (
            <section id='who-we-are' className="who-we-are ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>WHO</span> WE ARE?</h2>
                        <p>We're young and ambitious full-service digital group of companies with a result-driven approach that integrates digital marketing, technology, statistics & analytics to deliver cutting-edge solutions. Our dynamic team with its deep understanding of business needs and sharing values attract more and more clients from day to day.</p>
                        <p>By combining technology, innovation and creativity in digital services, we demonstrate a broad expertise across a variety of digital media services. We are proud to serve as a trusted mentor to every business we are engaged in.</p>
                        <p>Our expertise is confirmed by a <AnchorLink className='bolder' href="#our-projects">plenty of projects</AnchorLink> individually customized for clients’ expectations and successfully implemented and introduced to the market.</p>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhoWeAre;