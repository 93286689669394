import React from 'react';

class OurTeam extends React.Component {
    render(){
        return (
            <section id='our-team' className="strategy-area bg-f6f6f6">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="image"></div>
                        </div>
                        
                        <div className="col-lg-6 col-md-7">
                            <div className="about-strategy ptb-80">
                                <div className="section-title">
                                    <h2><span>OUR</span> TEAM</h2>
                                    <p>STNL Media Invest Holding is about people</p>
                                    <p>We assemble a truly dynamic team based on both professionalism and character. Our programmers, developers, web designers, data scientists, engineers, technology leads, managers, legal, cybersecurity and lots of other experts working insistently and passionately on every project.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default OurTeam;