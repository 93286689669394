import React from 'react';
import adIcon from '../../assets/images/icons/ad.svg';
import adIconWhite from '../../assets/images/icons/adWhite.svg';
import esportsIcon from '../../assets/images/icons/esports.svg';
import esportsIconWhite from '../../assets/images/icons/esportsWhite.svg';
import mediaIcon from '../../assets/images/icons/media.svg';
import mediaIconWhite from '../../assets/images/icons/mediaWhite.svg';
import statIcon from '../../assets/images/icons/stat.svg';
import statIconWhite from '../../assets/images/icons/statWhite.svg';
import technologyIcon from '../../assets/images/icons/technology.svg';
import technologyIconWhite from '../../assets/images/icons/technologyWhite.svg';
import weatherIcon from '../../assets/images/icons/weather.svg';
import weatherIconWhite from '../../assets/images/icons/weatherWhite.svg';


class Directions extends React.Component {
    constructor() {
        super();

        this.state = {
            technologyIconSrc: technologyIcon,
            adIconSrc: adIcon,
            statIconSrc: statIcon,
            mediaIconSrc: mediaIcon,
            esportsIconSrc: esportsIcon,
            weatherIconSrc: weatherIcon,
        }
    }

    handleTechnologyWhite = () => {
        this.setState({ technologyIconSrc: technologyIconWhite })
    }
    handleTechnologyDefault = () => {
        this.setState({ technologyIconSrc: technologyIcon })
    }
    handleAdWhite = () => {
        this.setState({ adIconSrc: adIconWhite })
    }
    handleAdDefault = () => {
        this.setState({ adIconSrc: adIcon })
    }
    handleStatWhite = () => {
        this.setState({ statIconSrc: statIconWhite })
    }
    handleStatDefault = () => {
        this.setState({ statIconSrc: statIcon })
    }
    handleMediaWhite = () => {
        this.setState({ mediaIconSrc: mediaIconWhite })
    }
    handleMediaDefault = () => {
        this.setState({ mediaIconSrc: mediaIcon })
    }
    handleEsportsWhite = () => {
        this.setState({ esportsIconSrc: esportsIconWhite })
    }
    handleEsportsDefault = () => {
        this.setState({ esportsIconSrc: esportsIcon })
    }
    handleWeatherWhite = () => {
        this.setState({ weatherIconSrc: weatherIconWhite })
    }
    handleWeatherDefault = () => {
        this.setState({ weatherIconSrc: weatherIcon })
    }

    render(){
        return (
            <section id="directions" className="directions ptb-80">
                <div className="container">
                    <div className="text-left">
                        <h2><span>DIRECTIONS</span> OF WORK</h2>
                        <h2>AND CORE CONCEPTS</h2>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleTechnologyWhite()} onMouseLeave={() => this.handleTechnologyDefault()}>
                                    <div className='image'>
                                        <img src={this.state.technologyIconSrc} alt="icon"/>
                                    </div>
                                    <h4>INFORMATION TECHNOLOGY</h4>
                                    <span>1</span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleAdWhite()} onMouseLeave={() => this.handleAdDefault()}>
                                    <div className='image'>
                                        <img src={this.state.adIconSrc} alt="icon"/>
                                    </div>
                                    <h4>ADVERTISING</h4>
                                    <span>2</span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleStatWhite()} onMouseLeave={() => this.handleStatDefault()}>
                                    <div className='image'>
                                        <img src={this.state.statIconSrc} alt="icon"/>
                                    </div>
                                    <h4>SPORTS STATISTICS</h4>
                                    <span>3</span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleMediaWhite()} onMouseLeave={() => this.handleMediaDefault()}>
                                    <div className='image'>
                                        <img src={this.state.mediaIconSrc} alt="icon"/>
                                    </div>
                                    <h4>MEDIA</h4>
                                    <span>4</span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleEsportsWhite()} onMouseLeave={() => this.handleEsportsDefault()}>
                                    <div className='image'>
                                        <img src={this.state.esportsIconSrc} alt="icon"/>
                                    </div>
                                    <h4>ESPORTS</h4>
                                    <span>5</span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-who-we-are" onMouseEnter={() => this.handleWeatherWhite()} onMouseLeave={() => this.handleWeatherDefault()}>
                                    <div className='image'>
                                        <img src={this.state.weatherIconSrc} alt="icon"/>
                                    </div>
                                    <h4>WEATHER DATA</h4>
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Directions;