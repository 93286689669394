import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import project_1 from '../../assets/images/projects/project_1.png'
import project_2 from '../../assets/images/projects/project_2.png'
import project_3 from '../../assets/images/projects/project_3.png'
import project_4 from '../../assets/images/projects/project_4.png'
import project_5 from '../../assets/images/projects/project_5.png'
import project_6 from '../../assets/images/projects/project_6.png'
import project_7 from '../../assets/images/projects/project_7.png'
import project_8 from '../../assets/images/projects/project_8.png'

class OurProjects extends React.Component {
    constructor() {
        super();

        this.state = {
            show: false,
            project: '',
        }
    }

    modalContent = () => {
        if (this.state.project === 'project_1') {
            return (
                <>
                    <p>LIVESCORE.BIZ is a platform for tracking the results of any team or sport. It provides you with an opportunity to view top popular livescores of any sport of your interest. You may check the results of absolutely all football matches and other sport games wherever they take place.</p>
                    <span>LIVESCORE.BIZ features:</span>
                    <ul>
                        <li>top-fullest list of all sports markets available 24/7;</li>
                        <li>quickest block processing speed for getting the latest results;</li>
                        <li>possibility to check game predictions to place a successful bet, etc.;</li>
                        <li>coverage of livescore results from well-known leagues and competitions to less known around the world.</li>
                    </ul>
                </>
            );
        } else if (this.state.project === 'project_2') {
            return (
                <>
                    <p>Azscore is a service for tracking the results of your favorite team. Wherever you are, the most up-to-date information, text translation of online matches and full sports statistics are always at hand!</p>
                    <span>AZSCORE features:</span>
                    <ul>
                        <li>up-to-date information on sport events worldwide;</li>
                        <li>text translation of online matches;</li>
                        <li>full sports statistics from around the world;</li>
                        <li>constantly updating results and live broadcasts.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_3') {
            return (
                <>
                    <p>Since 2007 FScore has been presenting the results of football matches. Users of the platform can watch not only today's, yesterday's or live game scores, but also can get predictions for future games, and more.</p>
                    <span>With FScore you may:</span>
                    <ul>
                        <li>stay updated with livescores on game results and most important match events;</li>
                        <li>check most relevant and latest odds and predictions for more than 2000 league matches;</li>
                        <li>see relevant football schedules for teams and tournaments;</li>
                        <li>track all the details and events happening during the game;</li>
                        <li>add matches you’re most interested in to favorites to follow and quickly get their scores.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_4') {
            return (
                <>
                    <p>METEOPROG - one of the first projects of the weather forecast sites emerged in the post-soviet area with the coverage of more than 170 countries worldwide.</p>
                    <span>Features:</span>
                    <ul>
                        <li>real-time information about the weather forecast;</li>
                        <li>coverage of more than 170 countries and around 15000 cities and places worldwide;</li>
                        <li>usage of a highly effective WRF model (Weather Research and Forecasting), which provides accurate numerical weather prediction data;</li>
                        <li>tconstant development and improvement of weather forecast calculation models, which allows to increase the amount of covered cities and improve the accuracy of weather forecasts.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_5') {
            return (
                <>
                    <p>777Score is one of the best sports platforms for the millions of fans around the globe: scores, stats, and live results delivered right to you!</p>
                    <p>777score brings you results, standings, sports scores, betting analytics from world-known leagues and much more from every competition or league in the world.</p>
                    <span>777Score features:</span>
                    <ul>
                        <li>real-time live standing of sports games. All scores are updated in live mode;</li>
                        <li>the latest scores, leaders, and standings for the sport you cheer;</li>
                        <li>all team clashes information and statistics handpicked and crystallized.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_6') {
            return (
                <>
                    <p>GoAdvert - is a special ad-network tool for those who are looking to scale their advertising strategy from one website to a network of multiple publishers. GoAdvert can help to start the ad network of publishers and advertisers.</p>
                    <span>Features:</span>
                    <ul>
                        <li>simultaneous operation of multiple websites;</li>
                        <li>live ad performance reports issuing and broken down by each website;</li>
                        <li>own ad network creation with partner programme;</li>
                        <li>customization of modules and features;</li>
                        <li>the possibility of geographic targeting provides wide opportunities for advertising in 50+ countries, as well as in local regions or cities.</li>
                        <li>a variety of formats of advertising placed - branding, banners, pre-rolls and so on.</li>
                        <li>multi-level analytics, which allows the customer to get statistics, and visualize it for each element of the advertising campaign.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_7') {
            return (
                <>
                    <p>WEPLAY - the first esportstainment company that blends esports, storytelling, and entertainment content.</p>
                    <p>Since 2012, the company has been organizing esports online tournaments and LAN finals.</p>
                    <p>The broadcasts, which are watched by millions of viewers, come from the studio on Twitch channels and other streaming platforms. The company also develops its own online media and esports tournament platform.</p>
                    <span>WePlay! Esports expertise:</span>
                    <ul>
                        <li>organization of esports events;</li>
                        <li>brand integration;</li>
                        <li>creation of video content;</li>
                        <li>content distribution;</li>
                        <li>creation of software solutions for esports;</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_8') {
            return (
                <>
                    <p>Sport Solutions is a platform that displays sports statistics, news and video content of various sports with the possibility of advertisement placement. It provides a full cycle of processing and output information with monetization through advertising.</p>
                    <span>Features:</span>
                    <ul>
                        <li>display of sports statistics/events/games/ news and video content;</li>
                        <li>the ability to select the display for various sports, teams, champions, players by country, multilingualism;</li>
                        <li>historical data, selection of display with results of sports events by days;</li>
                        <li>flexible settings for news and video content display;</li>
                        <li>a wide range of tools on platform administration / moderation.</li>
                        <li>placement of advertisements on the  Sport Solutions platform, customization of interface and analytics.</li>
                    </ul>
                </>
            )
        } else if (this.state.project === 'project_9') {
            return (
                <>
                    <p>Sport Solutions is a platform that displays sports statistics, news and video content of various sports with the possibility of advertisement placement. It provides a full cycle of processing and output information with monetization through advertising.</p>
                    <span>Features:</span>
                    <ul>
                        <li>display of sports statistics/events/games/ news and video content;</li>
                        <li>the ability to select the display for various sports, teams, champions, players by country, multilingualism;</li>
                        <li>historical data, selection of display with results of sports events by days;</li>
                        <li>flexible settings for news and video content display;</li>
                        <li>a wide range of tools on platform administration / moderation.</li>
                        <li>placement of advertisements on the  Sport Solutions platform, customization of interface and analytics.</li>
                    </ul>
                </>
            )
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleOpen = project => {
        this.setState({ show: true })
        this.setState({ project: project })
    }

    render(){
        return (
            <section id='our-projects' className="our-projects ptb-1">
                <div className="container">
                    <div className="section-title">
                        <h2><span>CURRENT</span> PROJECTS</h2>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_1} alt="project-pic"/>
                                    <p>A platform of top-fullest list of all sports markets available 24/7.</p>
                                    <Button onClick={() => this.handleOpen('project_1')}>+</Button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_2} alt="project-pic"/>
                                    <p>A sport-tracking service with the most up-to-date information, text translation of online matches and full sports statistics.</p>
                                    <Button onClick={() => this.handleOpen('project_2')}>+</Button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_3} alt="project-pic"/>
                                    <p>A platform covering the most important sport statistics from all over the world of more than 2000 league matches.</p>
                                    <Button onClick={() => this.handleOpen('project_3')}>+</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_4} alt="project-pic"/>
                                    <p>One of the first projects of the weather forecast sites emerged in the
                                        post-soviet area with the coverage of more than 170 countries worldwide.</p>
                                    <Button onClick={() => this.handleOpen('project_4')}>+</Button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_5} alt="project-pic"/>
                                    <p>A service for tracking sport results full sports statistics at hand.</p>
                                    <Button onClick={() => this.handleOpen('project_5')}>+</Button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_6} alt="project-pic"/>
                                    <p>GoAdvert can help to start the ad network of publishers and advertisers.</p>
                                    <Button onClick={() => this.handleOpen('project_6')}>+</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_7} alt="project-pic"/>
                                    <p>The first esportainment company that blends esports, storytelling, and
                                        entertainment content.</p>
                                    <Button onClick={() => this.handleOpen('project_7')}>+</Button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className='project-item'>
                                    <img src={project_8} alt="project-pic"/>
                                    <p>The Sport Solutions platform is an all-in-one solution for development of own sports statistics, news and video content websites.</p>
                                    <Button onClick={() => this.handleOpen('project_8')}>+</Button>
                                </div>
                            </div>
                        </div>
                        <Modal size='lg' show={this.state.show} onHide={() => this.handleClose()}>
                            <Modal.Body>{this.modalContent()}</Modal.Body>
                            <Modal.Footer className='text-center'>
                                <Button variant='outline-primary' onClick={() => this.handleClose()}>
                                    X
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default OurProjects;