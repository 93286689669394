import React from 'react';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ourValues from '../../assets/images/our_values.jpg';

class OurValues extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="our-values" className="about-area bg-f6f6f6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 py-75">
                            <div className="section-title">
                                <h2><span>OUR</span> VALUES</h2>
                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Move with integrity</li>
                                    <li><i className="fa fa-check"></i>Excel at every step</li>
                                    <li><i className="fa fa-check"></i>Create innovation</li>
                                </ul>

                                <ul>
                                    <li><i className="fa fa-check"></i>Thrive together</li>
                                    <li><i className="fa fa-check"></i>Grow leaders</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 absolute-image">
                            <img src={ourValues} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default OurValues;