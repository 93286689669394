import React from 'react';
import MainBanner from '../PageBanners/MainBanner';
import OurValues from '../Common/OurValues';
import OurTeam from '../Common/OurTeam';
import WhoWeAre from '../Common/WhoWeAre';
import WeAre from '../Common/WeAre';
import OurVisions from '../Common/OurVisions';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import OurGoals from '../Common/OurGoals';
import Directions from '../Common/Directions';
import OurProjects from '../Common/OurProjects';
 
class Home extends React.Component {
    render(){
        return (
            <React.Fragment>
                {/* Main Banner */}
                <MainBanner />
                {/* Who We Are Area */}
                <WhoWeAre />
                {/* Our visions */}
                <OurVisions />
                {/* Our values */}
                <OurValues />
                {/* We are */}
                <WeAre />
                {/* Our goals */}
                <OurGoals />
                {/* Directions */}
                <Directions />
                {/* Our team */}
                <OurTeam />
                {/* Our projects */}
                <OurProjects />
                {/* Contact Area */}
                <Contact />
                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default Home;