import React from 'react';
import logo from '../../assets/images/logo.svg'
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <img src={logo} alt="logo"/>
                            <p>MEDIA INVEST HOLDING</p>
                            <p>Copyright <i className="fa fa-copyright"></i> 2019 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;