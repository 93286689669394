import React from 'react';
import ourVisions from '../../assets/images/our_visions.jpg'

const OurVisions = () => {
    return (
        <section id='our-visions' className="ctr-area ptb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <img src={ourVisions} alt="image"/>
                        <div className="section-title mb-0">
                            <h1><span>OUR</span> VISION</h1>
                            <p>At STNL Media Invest Holding, we embrace creative spirit, innovative work style and result-driven approach to be the best for the benefit of our clients, partners and customers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
 
export default OurVisions;