import React from 'react';

class OurGoals extends React.Component {
    render(){
        return (
            <section id='our-goals' className="who-we-are ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>OUR</span> GOALS</h2>
                        <p>At STNL MEDIA INVEST HOLDING, we aim to support and develop small media businesses in order to transform them into the giant market players. We are convinced that everything great grows out of small, therefore we devote a significant part of our work to investing in new ambitious digital projects, thereby supporting small and medium businesses.</p>
                        <p>We combine innovative technologies with modern business processes to succeed in each business area. With the focus on IT and expertise in sport statistics, we help you build a successful business. We do our best to make our projects more powerful within the market players.</p>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default OurGoals;