import React from 'react';
import progressiveIcon from '../../assets/images/icons/progressiveIcon.svg';
import itIcon from '../../assets/images/icons/itIcon.svg';
import sportIcon from '../../assets/images/icons/sportIcon.svg';
import ambitiousIcon from '../../assets/images/icons/ambitiousIcon.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

class WeAre extends React.Component {
    componentWillMount() {
        AOS.init();
    }

    render(){
        return (
            <section id='we-are' className="we-are ptb-80">
                <div className="container">
                    <div className="text-center">
                        <h2><span>WE</span> ARE:</h2>
                        <div className="row">
                            <div data-aos="fade-right" data-aos-duration="1500" className="col-lg-3 col-md-6">
                                <img src={progressiveIcon} alt="progressiveIcon"/>
                                <span>PROGRESSIVE</span>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="1500" className="col-lg-3 col-md-6">
                                <img src={itIcon} alt="itIcon"/>
                                <span>IT-ORIENTED</span>
                            </div>
                            <div data-aos="fade-left" data-aos-duration="1500" className="col-lg-3 col-md-6">
                                <img src={sportIcon} alt="sportIcon"/>
                                <span>SPORT-SAVVY</span>
                            </div>
                            <div data-aos="fade-left" data-aos-duration="1500" className="col-lg-3 col-md-6">
                                <img src={ambitiousIcon} alt="ambitiousIcon"/>
                                <span>AMBITIOUS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WeAre;